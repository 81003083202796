import { Form, Modal, Input, Select } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-common';
import _ from 'lodash';
import React from 'react';
import type { WarehouseBinTreeItem } from './types';

interface CallbackParams {
  warehouse_area_type?: string;
  warehouse_area_name?: string;
}

interface WarehouseAreaAddAndEditModalProps {
  callback: (params: CallbackParams) => Promise<unknown>;
  onCancel: (...arg: any[]) => any;
  title: string;
  treeNodeForWarehouseArea?: WarehouseBinTreeItem;
}

export function WarehouseAreaAddAndEditModal({
  callback,
  onCancel,
  title,
  treeNodeForWarehouseArea,
}: WarehouseAreaAddAndEditModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: CallbackParams; }>({
    loading: false,
    params: null,
  });

  const [
    warehouseAreaData,
    setWarehouseAreaData,
  ] = React.useState<ValueAndLabelData>([]);

  React.useEffect(() => {
    request<BaseData<Array<{ code: string; name: string; }>>>({
      url: '/api/cloud/baseinfo/rest/common/dictList',
      params: { type: 'warehouse_area_type' },
    })
      .then((info) => {
        setWarehouseAreaData((info.data || []).map((item) => ({
          value: _.toString(item.code),
          label: item.name,
        })));
      });
  }, []);

  React.useEffect(() => {
    if (treeNodeForWarehouseArea?.id) {
      request<{ properties?: { warehouse_area_name?: string; warehouse_area_type?: number; }; }>({ url: `/api/cloud/baseinfo/rest/warehouse/area/${treeNodeForWarehouseArea.id}` })
        .then((info) => {
          const warehouseAreaType = info?.properties?.warehouse_area_type;
          form.setFieldsValue({
            warehouse_area_name: info?.properties?.warehouse_area_name,
            warehouse_area_type: warehouseAreaType === -1 ? undefined : _.toString(warehouseAreaType) || undefined,
          });
        });
    }
  }, []);

  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title={title}
      visible
      width={400}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="库区"
          name="warehouse_area_name"
          rules={[
            {
              required: true,
              message: '请输入库区',
            },
          ]}
        >
          <Input
            allowClear
            placeholder="请输入库区"
          />
        </Form.Item>
        <Form.Item
          label="库区类型"
          name="warehouse_area_type"
        >
          <Select
            allowClear
            notFoundContent="没有数据"
            options={warehouseAreaData}
            placeholder="请选择库区类型"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
