import { Card, Modal, Tabs } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { WAREHOUSE_SHELF_BIND_TYPE } from './constants';
import type { WarehouseShelfSettingModalStore } from './warehouseShelfSettingModalStore';

interface WarehouseShelfSettingModalProps {
  warehouseShelfSettingModalStore: WarehouseShelfSettingModalStore;
}

export const WarehouseShelfSettingModal: React.FC<WarehouseShelfSettingModalProps> = observer(({ warehouseShelfSettingModalStore }) => {
  const {
    visible,
    handleClose,
  } = warehouseShelfSettingModalStore;
  return (
    <Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={handleClose}
      open={visible}
      title="设置货架绑定商品"
      width={1100}
    >
      <Pane warehouseShelfSettingModalStore={warehouseShelfSettingModalStore}/>
    </Modal>
  );
});

const Pane = observer(({
  warehouseShelfSettingModalStore: {
    bindType,
    handlePlusServiceSettingTypeChange,
    ownerStore,
    skuStore,
    supplierStore,
  },
}: { warehouseShelfSettingModalStore: WarehouseShelfSettingModalStore; }) => {
  return (
    <Tabs
      activeKey={bindType}
      items={[
        {
          key: WAREHOUSE_SHELF_BIND_TYPE.owner.value,
          label: WAREHOUSE_SHELF_BIND_TYPE.owner.label,
          children: (
            <>
              <Card size="small">
                <NormalProgrammeComponent store={ownerStore.normalProgramme}/>
              </Card>
              <div style={{ height: 480 }}>
                <MainSubStructure store={ownerStore.mainSubStructureModel}/>
              </div>
            </>
          ),
        },
        {
          key: WAREHOUSE_SHELF_BIND_TYPE.sku.value,
          label: WAREHOUSE_SHELF_BIND_TYPE.sku.label,
          children: (
            <>
              <Card size="small">
                <NormalProgrammeComponent store={skuStore.normalProgramme}/>
              </Card>
              <div style={{ height: 480 }}>
                <MainSubStructure store={skuStore.mainSubStructureModel}/>
              </div>
            </>
          ),
        },
        {
          key: WAREHOUSE_SHELF_BIND_TYPE.supplier.value,
          label: WAREHOUSE_SHELF_BIND_TYPE.supplier.label,
          children: (
            <>
              <Card size="small">
                <NormalProgrammeComponent store={supplierStore.normalProgramme}/>
              </Card>
              <div style={{ height: 480 }}>
                <MainSubStructure store={supplierStore.mainSubStructureModel}/>
              </div>
            </>
          ),
        },
      ]}
      onChange={handlePlusServiceSettingTypeChange}
    />
  );
});
