import { Form, Modal, Input, InputNumber, Checkbox } from 'antd';
import { RenderByCondition } from 'egenie-common';
import React from 'react';

interface CallbackParams {
  safeStockNum?: number;
}

interface EditSafeStockModalProps {
  callback: (params: CallbackParams) => Promise<unknown>;
  onCancel: (...arg: any[]) => any;
}

export function EditSafeStockModal({
  callback,
  onCancel,
}: EditSafeStockModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: CallbackParams; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="设置安全库存"
      visible
      width={400}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="安全库存"
          name="safeStockNum"
          rules={[
            {
              required: true,
              message: '请输入安全库存',
            },
            {
              type: 'number',
              min: 0,
              message: '最小为0',
            },
          ]}
        >
          <InputNumber
            min={0}
            placeholder="请输入安全库存"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
