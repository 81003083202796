import { request } from 'egenie-common';
import { action, computed, observable } from 'mobx';
import React from 'react';
import type { Store } from './store';
import type { WarehouseBinTreeItem } from './types';

function formatTreeListData(data: WarehouseBinTreeItem[]): WarehouseBinTreeItem[] {
  return (Array.isArray(data) ? data : []).map((item) => ({
    ...item,
    title: item.name,
    key: item.id,
  }));
}

function formatTreeData(data: WarehouseBinTreeItem[]): WarehouseBinTreeItem[] {
  const treeMap = new Map<string, WarehouseBinTreeItem>();
  data.forEach((item) => {
    treeMap.set(item.id, item);
  });

  data.forEach((item) => {
    if (treeMap.has(item.pid)) {
      const parentItem = treeMap.get(item.pid);
      if (parentItem.children) {
        parentItem.children.push(item);
      } else {
        parentItem.children = [item];
      }
    }
  });

  return data.filter((item) => !item.pid);
}

export class TreeStore {
  constructor(private parent: Store) {
    this.getTreeData();
  }

  public toParams = () => {
    if (this.selectedItem) {
      return {
        wareid: this.selectedItem.id,
        waretype: this.selectedItem.type,
      };
    }

    return {};
  };

  @observable.ref public treeListData: WarehouseBinTreeItem[] = [];

  @computed
  public get treeMap(): Map<string, WarehouseBinTreeItem> {
    const map: Map<string, WarehouseBinTreeItem> = new Map<string, WarehouseBinTreeItem>();
    this.treeListData.forEach((item) => {
      map.set(item.id, item);
    });
    return map;
  }

  @computed
  public get treeData(): WarehouseBinTreeItem[] {
    return formatTreeData(this.treeListData);
  }

  @action public getTreeData = () => {
    request<WarehouseBinTreeItem[]>({ url: '/api/cloud/baseinfo/rest/warehouse/category/list' })
      .then((info) => {
        this.treeListData = formatTreeListData(info);
        this.expandedKeys = this.treeListData.map((item) => item.id);
      });
  };

  @observable public expandedKeys: string[] = [];

  @action
  public onExpand = (expandedKeys: string[]) => {
    this.expandedKeys = expandedKeys;
  };

  @observable public selectedKeys: string[] = [];

  @computed
  public get selectedItem(): WarehouseBinTreeItem {
    return this.treeListData.find((item) => this.selectedKeys.includes(item.id));
  }

  @action
  public onSelect = (selectedKeys: string[]) => {
    if (selectedKeys && selectedKeys.length) {
      this.selectedKeys = selectedKeys;
      this.parent.mainSubStructureModel.gridModel.onQuery();
    }
  };
}

