import { Tree, Layout, Card, Popover, Button, Space } from 'antd';
import { MainSubStructure, NormalProgrammeComponent } from 'egenie-utils';
import { egeniePcTheme } from 'egenie-common';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';
import type { WarehouseBinTreeItem } from './types';
import { WAREHOUSE_BIN_TREE_NODE_TYPE } from './types';
import { WarehouseShelfSettingModal } from './warehouseShelfSettingModal/warehouseShelfSettingModal';

@observer
class TreeNodeTitleRender extends React.Component<{ store: Store; data: WarehouseBinTreeItem; }> {
  render() {
    const {
      data,
      store: {
        warehouseShelfSettingModalStore: { handleOpen },
        warehouseInternalStore: {
          handleWarehouseAreaCreate,
          handleWarehouseAreaDelete,
          handleWarehouseAreaEdit,
          handleWarehouseShelfCreate,
          handleWarehouseShelfEdit,
          handleWarehouseShelfDelete,
        },
        treeStore: { treeMap },
        mainSubStructureModel,
      },
    } = this.props;

    if (!data) {
      return null;
    }

    switch (data.type) {
      case WAREHOUSE_BIN_TREE_NODE_TYPE.root:
        return data.name;
      case WAREHOUSE_BIN_TREE_NODE_TYPE.warehouse:
        return (
          <Popover
            arrowPointAtCenter
            content={(
              <Button
                onClick={() => handleWarehouseAreaCreate(data)}
                type="text"
              >
                <i
                  className="icon-add"
                  style={{ color: egeniePcTheme.color['egenie-primary-color'] }}
                />
                &nbsp;
                新建
              </Button>
            )}
            trigger="contextMenu"

            // @ts-ignore
            zIndex={1000}
          >
            {data.name}
          </Popover>
        );
      case WAREHOUSE_BIN_TREE_NODE_TYPE.warehouseArea:
        return (
          <Popover
            arrowPointAtCenter
            content={(
              <Space direction="vertical">
                <Button
                  onClick={() => handleWarehouseShelfCreate(data)}
                  type="text"
                >
                  <i
                    className="icon-add"
                    style={{ color: egeniePcTheme.color['egenie-primary-color'] }}
                  />
                  &nbsp;
                  新建
                </Button>
                <Button
                  onClick={() => handleWarehouseAreaEdit(data)}
                  type="text"
                >
                  <i
                    className="icon-edit"
                    style={{ color: egeniePcTheme.color['egenie-primary-color'] }}
                  />
                  &nbsp;
                  编辑
                </Button>
                {

                  // 有子节点不让删除
                  treeMap.get(data.id)?.children?.length > 0 ? null : (
                    <Button

                      onClick={() => handleWarehouseAreaDelete(data)}
                      type="text"
                    >
                      <i
                        className="icon-delete"
                        style={{ color: egeniePcTheme.color['egenie-primary-color'] }}
                      />
                      &nbsp;
                      删除
                    </Button>
                  )
                }
              </Space>
            )}
            trigger="contextMenu"

            // @ts-ignore
            zIndex={1000}
          >
            {data.name}
          </Popover>
        );
      case WAREHOUSE_BIN_TREE_NODE_TYPE.warehouseShelf:
        return (
          <Popover
            arrowPointAtCenter
            content={(
              <Space direction="vertical">
                <Button
                  onClick={() => handleWarehouseShelfEdit(data)}
                  type="text"
                >
                  编辑
                </Button>
                {

                  // 分页数据存在库位不让删除
                  mainSubStructureModel.gridModel.rows.find((item) => item.warehouse_shelf_id === data.id) ? null : (
                    <Button
                      onClick={() => handleWarehouseShelfDelete(data)}
                      type="text"
                    >
                      删除
                    </Button>
                  )
                }
                <Button
                  onClick={() => handleOpen(data)}
                  type="text"
                >
                  设置
                </Button>
              </Space>
            )}
            trigger="contextMenu"

            // @ts-ignore
            zIndex={1000}
          >
            {data.name}
          </Popover>
        );
      default:
        return null;
    }
  }
}

@observer
class LeftTree extends React.Component<{ store: Store; }> {
  render() {
    const { store } = this.props;
    const {
      expandedKeys,
      onExpand,
      onSelect,
      selectedKeys,
      treeData,
    } = store.treeStore;
    return (
      <Tree
        expandedKeys={toJS(expandedKeys)}
        onExpand={onExpand}
        onSelect={onSelect}
        selectedKeys={toJS(selectedKeys)}
        showLine
        titleRender={(data) => (
          <TreeNodeTitleRender
            data={data as WarehouseBinTreeItem}
            store={store}
          />
        )}
        treeData={treeData}
      />
    );
  }
}

const store = new Store();
export default function() {
  return (
    <>
      <Layout className={styles.warehouseBinContainer}>
        <Layout.Sider
          theme="light"
          width={300}
        >
          <LeftTree store={store}/>
        </Layout.Sider>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={store.normalProgramme}/>
          </Card>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={store.mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <WarehouseShelfSettingModal warehouseShelfSettingModalStore={store.warehouseShelfSettingModalStore}/>
    </>

  );
}
