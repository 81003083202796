import { Form, Modal, Input, InputNumber, Radio } from 'antd';
import type { ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-common';
import _ from 'lodash';
import React from 'react';
import type { WarehouseBinTreeItem } from './types';
import { getWarehouseBinType } from '../../utils';

interface CallbackParams {
  warehouse_shelf_name?: string;
  place_num?: number;
  type?: string;
}

interface WarehouseShelfAddAndEditModalProps {
  callback: (params: CallbackParams) => Promise<unknown>;
  onCancel: (...arg: any[]) => any;
  title: string;
  treeNodeForWarehouseShelf?: WarehouseBinTreeItem;
}

export function WarehouseShelfAddAndEditModal({
  callback,
  onCancel,
  title,
  treeNodeForWarehouseShelf,
}: WarehouseShelfAddAndEditModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: CallbackParams; }>({
    loading: false,
    params: null,
  });

  const [
    warehouseBinTypeData,
    setWarehouseBinTypeData,
  ] = React.useState<ValueAndLabelData>([]);

  React.useEffect(() => {
    if (treeNodeForWarehouseShelf?.id) {
      request<{ properties?: CallbackParams; }>({ url: `/api/cloud/wms/rest/warehouse/shelf/${treeNodeForWarehouseShelf.id}` })
        .then((info) => {
          form.setFieldsValue({
            warehouse_shelf_name: info?.properties?.warehouse_shelf_name,
            place_num: info?.properties?.place_num || undefined,
            type: _.toString(info?.properties?.type) || undefined,
          });
        });
    }
    getWarehouseBinType()
      .then((info) => setWarehouseBinTypeData(info));
  }, []);

  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title={title}
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{ type: '0' }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="货架"
          name="warehouse_shelf_name"
          rules={[
            {
              required: true,
              message: '请输入货架',
            },
          ]}
        >
          <Input
            allowClear
            placeholder="请输入货架"
          />
        </Form.Item>
        <Form.Item
          label="库位类型"
          name="type"
          rules={[
            {
              required: true,
              message: '请输入库位类型',
            },
          ]}
        >
          <Radio.Group options={warehouseBinTypeData}/>
        </Form.Item>
        <Form.Item
          label="顺序号"
          name="place_num"
          rules={[
            {
              required: false,
              message: '请输入顺序号',
            },
            {
              type: 'number',
              min: 1,
              message: '最小为1',
            },
          ]}
        >
          <InputNumber
            min={0}
            placeholder="请输入顺序号"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
