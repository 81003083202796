import { Space } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, NormalProgramme, request } from 'egenie-utils';
import React from 'react';
import { LogTooltip } from '../../../utils';
import type { WarehouseShelfOwnerItem } from '../types';
import { WAREHOUSE_SHELF_BIND_STATUS, WAREHOUSE_SHELF_BIND_TYPE } from './constants';
import type { WarehouseShelfSettingModalStore } from './warehouseShelfSettingModalStore';

export class OwnerStore {
  constructor(private readonly parent: WarehouseShelfSettingModalStore) {
    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
  }

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 120,
          frozen: true,
          formatter: ({ row }: { row: WarehouseShelfOwnerItem; }) => {
            return (
              <Space>
                {
                  row.hasSettled ? (
                    <a
                      onClick={() => this.parent.handleBindSetting({
                        bindType: WAREHOUSE_SHELF_BIND_TYPE.owner.value,
                        bind: false,
                        businessIds: [row.ownerId],
                      })}
                    >
                      解绑货架
                    </a>
                  ) : (
                    <a
                      onClick={() => this.parent.handleBindSetting({
                        bindType: WAREHOUSE_SHELF_BIND_TYPE.owner.value,
                        bind: true,
                        businessIds: [row.ownerId],
                      })}
                    >
                      绑定货架
                    </a>
                  )
                }
                <LogTooltip
                  children={(
                    <a>
                      日志
                    </a>
                  )}
                  entityId={row.ownerId}
                  height={230}
                  logBizModuleCode={200000}
                  popoverProps={{
                    title: '日志',
                    trigger: 'click',
                    placement: 'right',
                  }}
                  width={1000}
                />
              </Space>
            );
          },
        },
        {
          key: 'ownerName',
          name: '货主名称',
          width: 200,
        },
        {
          key: 'hasSettled',
          name: '设置状态',
          width: 80,
          formatter: ({ row }: { row: WarehouseShelfOwnerItem; }) => {
            return (
              <span>
                {row.hasSettled ? WAREHOUSE_SHELF_BIND_STATUS.yes.label : WAREHOUSE_SHELF_BIND_STATUS.no.label}
              </span>
            );
          },
        },
        {
          key: 'ownerType',
          name: '货主类型',
          width: 120,
        },
      ].filter(Boolean)
        .map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
      rows: [],
      primaryKeyField: 'ownerId',
      sortByLocal: false,
      showReset: true,
      showCheckBox: true,
      showSelectedTotal: true,
      showEmpty: true,
      pageSize: 50,
    },
    buttons: [
      {
        text: '绑定货架',
        handleClick: () => this.parent.handleBindSetting({
          bindType: WAREHOUSE_SHELF_BIND_TYPE.owner.value,
          bind: true,
          businessIds: Array.from(this.mainSubStructureModel.gridModel.selectedIds) as string[],
        }),
      },
      {
        text: '解绑货架',
        handleClick: () => this.parent.handleBindSetting({
          bindType: WAREHOUSE_SHELF_BIND_TYPE.owner.value,
          bind: false,
          businessIds: Array.from(this.mainSubStructureModel.gridModel.selectedIds) as string[],
        }),
      },
    ],
    api: {
      onQuery: ({
        filterParams = {},
        ...rest
      } = {}) => {
        return request<PaginationData<WarehouseShelfOwnerItem>>({
          url: '/api/cloud/wms/rest/warehouse/shelf/bind/owner/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
            warehouseShelfId: this.parent.mainItem?.id,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 4,
    filterItems: [
      {
        type: 'select',
        field: 'ownerId',
        label: '货主',
      },
    ],
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}
