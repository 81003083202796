import { message, Modal } from 'antd';
import { request } from 'egenie-common';
import { action, observable } from 'mobx';
import React from 'react';
import { getAllVendor, getOwner } from '../../../utils';
import type { WarehouseShelfBindParams, WarehouseBinTreeItem } from '../types';
import { WAREHOUSE_SHELF_BIND_TYPE } from './constants';
import { OwnerStore } from './ownerStore';
import { SkuStore } from './skuStore';
import { SupplierStore } from './supplierStore';

export class WarehouseShelfSettingModalStore {
  @observable.ref public ownerStore: OwnerStore = new OwnerStore(this);

  @observable.ref public skuStore: SkuStore = new SkuStore(this);

  @observable.ref public supplierStore: SupplierStore = new SupplierStore(this);

  public mainItem: Partial<WarehouseBinTreeItem> = {};

  @observable public visible = false;

  @action public handleOpen = (mainItem: WarehouseBinTreeItem): void => {
    this.visible = true;
    this.mainItem = mainItem;

    this.handleCategoryChangeRefresh();

    getOwner()
      .then((data) => this.ownerStore.normalProgramme.filterItems.addDict({ ownerId: data }));
    getAllVendor()
      .then((data) => {
        this.skuStore.normalProgramme.filterItems.addDict({ vendorIds: data });
        this.supplierStore.normalProgramme.filterItems.addDict({ vendorShopIds: data });
      });
  };

  @action public handleClose = (): void => {
    this.visible = false;
    this.bindType = WAREHOUSE_SHELF_BIND_TYPE.owner.value;
    this.mainItem = {};

    this.ownerStore.normalProgramme.reset();
    this.ownerStore.mainSubStructureModel.gridModel.clearToOriginal();
    this.skuStore.normalProgramme.reset();
    this.skuStore.mainSubStructureModel.gridModel.clearToOriginal();
    this.supplierStore.normalProgramme.reset();
    this.supplierStore.mainSubStructureModel.gridModel.clearToOriginal();
  };

  @action public handleCategoryChangeRefresh = (isRefresh = false): void => {
    switch (this.bindType) {
      case WAREHOUSE_SHELF_BIND_TYPE.owner.value:
        this.ownerStore.mainSubStructureModel.gridModel.resetAll();
        if (isRefresh) {
          this.ownerStore.mainSubStructureModel.onRefresh();
        } else {
          this.ownerStore.mainSubStructureModel.onQuery();
        }
        break;
      case WAREHOUSE_SHELF_BIND_TYPE.sku.value:
        this.skuStore.mainSubStructureModel.gridModel.resetAll();
        if (isRefresh) {
          this.skuStore.mainSubStructureModel.onRefresh();
        } else {
          this.skuStore.mainSubStructureModel.onQuery();
        }
        break;
      case WAREHOUSE_SHELF_BIND_TYPE.supplier.value:
        this.supplierStore.mainSubStructureModel.gridModel.resetAll();
        if (isRefresh) {
          this.supplierStore.mainSubStructureModel.onRefresh();
        } else {
          this.supplierStore.mainSubStructureModel.onQuery();
        }
        break;
    }
  };

  @observable public bindType = WAREHOUSE_SHELF_BIND_TYPE.owner.value;

  @action public handlePlusServiceSettingTypeChange = (categoryType: string): void => {
    this.bindType = categoryType;
    this.handleCategoryChangeRefresh();
  };

  public handleBindSetting = (params: WarehouseShelfBindParams) => {
    const data: WarehouseShelfBindParams = {
      ...params,
      warehouseShelfId: this.mainItem.id,
    };

    if (data.businessIds.length === 0) {
      const error = '请至少选择一条数据';
      message.warn({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      content: `确定${data.bind ? '绑定' : '解绑'}吗?`,
      onOk: () => request({
        url: '/api/cloud/wms/rest/warehouse/shelf/bind/shelf',
        method: 'post',
        data,
      })
        .then(() => {
          message.success('设置成功');
          this.handleCategoryChangeRefresh();
        }),
    });
  };
}

