export interface WarehouseBinItem {
  tenant_id?: any;
  note?: any;
  warehousebinvo_id?: any;
  scan_code?: any;
  owner_id?: string;
  created_at?: string;
  warehouse_bin_id?: string;
  warehouse_bin_v_o_id?: any;
  capacity?: number;
  is_enabled?: string;
  last_updater?: any;
  warehouse_bin_name?: any;
  warehouse_area_type?: string;
  warehouse_no?: any;
  warehouse_area_id?: string;
  min_capacity?: any;
  warehouse_shelf_id?: string;
  warehouse_area_name?: string;
  warehouse_shelf_no?: any;
  creator?: any;
  last_updated?: string;
  max_capacity?: any;
  warehouse_shelf_name?: string;
  is_default?: any;
  is_tmp?: string;
  warehouse_area_no?: any;
  warehouse_bin_no?: string;
  warehouse_name?: string;
  is_usable?: string;
  warehouse_id?: string;
  safeStockNum?: number;
  warehouseAreaNumType?: number;
}

export interface WarehouseBinTreeItem {
  id?: string;
  name?: string;
  pid?: string;
  type?: WAREHOUSE_BIN_TREE_NODE_TYPE;
  title?: string;
  key: string;
  children?: WarehouseBinTreeItem[];
}

export enum WAREHOUSE_BIN_TREE_NODE_TYPE {
  root = 'super',
  warehouse = 'warehouse',
  warehouseArea = 'warehouse_area',
  warehouseShelf = 'warehouse_shelf'
}

export interface WarehouseShelfBindParams {
  warehouseShelfId?: string;
  bindType: string | number;
  businessIds: string[];
  bind: boolean;
}

export interface WarehouseShelfOwnerItem {
  hasSettled?: boolean;
  bindType?: number;
  warehouseShelfBindDetailId?: string;
  ownerId?: string;
  ownerName?: string;
  ownerType?: string;
}

export interface WarehouseShelfSkuItem {
  hasSettled?: boolean;
  bindType?: number;
  warehouseShelfBindDetailId?: string;
  skuId?: string;
  skuNo?: string;
  productNo?: string;
  color?: string;
  size?: string;
  pic?: string;
}

export interface WarehouseShelfSupplierItem {
  hasSettled?: boolean;
  bindType?: number;
  warehouseShelfBindDetailId?: string;
  vendorShopId?: string;
  vendorNo?: string;
  vendorName?: string;
  vendorMobile?: string;
}
