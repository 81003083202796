import { Form, Modal, Input, InputNumber, Checkbox } from 'antd';
import { RenderByCondition } from 'egenie-common';
import React from 'react';

interface CallbackParams {
  warehouse_bin_no?: string;
  capacity?: number;
  is_tmp?: boolean;
  safeStockNum?: number;
}

interface WarehouseBinAddAndEditModalProps {
  callback: (params: CallbackParams) => Promise<unknown>;
  onCancel: (...arg: any[]) => any;
  title: string;
  initialValues?: CallbackParams;
}

export function WarehouseBinAddAndEditModal({
  callback,
  onCancel,
  title,
  initialValues,
}: WarehouseBinAddAndEditModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: CallbackParams; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title={title}
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{
          capacity: 8888,
          is_tmp: false,
          ...initialValues,
        }}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="库位"
          name="warehouse_bin_no"
          rules={[
            {
              required: true,
              message: '请输入库位',
            },
          ]}
        >
          <Input
            allowClear
            placeholder="请输入库位"
          />
        </Form.Item>
        <Form.Item
          label="库位容量"
          name="capacity"
          rules={[
            {
              required: true,
              message: '请输入库位容量',
            },
            {
              type: 'number',
              min: 0,
              message: '最小为0',
            },
          ]}
        >
          <InputNumber
            min={0}
            placeholder="请输入库位容量"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <RenderByCondition show={!initialValues?.warehouse_bin_no}>
          <Form.Item
            label="安全库存"
            name="safeStockNum"
            rules={[
              {
                required: false,
                message: '请输入安全库存',
              },
              {
                type: 'number',
                min: 0,
                message: '最小为0',
              },
            ]}
          >
            <InputNumber
              min={0}
              placeholder="请输入安全库存"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </RenderByCondition>
        <Form.Item
          label="是否临时库位"
          name="is_tmp"
          valuePropName="checked"
        >
          <Checkbox/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
