import { message, Space } from 'antd';
import type { PureData } from 'egenie-utils';
import { MainSubStructureModel, NormalProgramme, request } from 'egenie-utils';
import React from 'react';
import { EditSafeStockModal } from './editSafeStockModal';
import { TreeStore } from './treeStore';
import type { WarehouseBinItem, WarehouseBinTreeItem } from './types';
import { WarehouseInternalStore } from './warehouseInternalStore';
import { ImportFile } from '../../utils';
import { destroyAllModal, renderModal, getStaticResourceUrl } from 'egenie-common';
import { WarehouseShelfSettingModalStore } from './warehouseShelfSettingModal/warehouseShelfSettingModalStore';
import { action } from 'mobx';

export class Store {
  constructor() {
    this.normalProgramme.filterItems.connect(this.treeStore);
    this.mainSubStructureModel.gridModel.onQuery();
  }

  @action public handleEditSafeStock = () => {
    const rows: WarehouseBinItem[] = this.mainSubStructureModel.gridModel.selectRows;
    if (rows.length <= 0) {
      const error = '请至少选择一条数据';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    if (rows.some((item) => item.warehouseAreaNumType !== 1)) {
      const error = '库区类型不是拣货区,请在拣货区设置安全库存';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    renderModal(
      <EditSafeStockModal
        callback={(async(data) => {
          await request({
            url: '/api/cloud/baseinfo/rest/warehouse/bin/batch/set/safe/stock',
            method: 'post',
            data: {
              safeStockNum: data.safeStockNum,
              warehouseBinIds: rows.map((item) => item.warehouse_bin_id)
                .join(','),
            },
          });

          message.success('操作成功');
          this.mainSubStructureModel.onRefresh();
          destroyAllModal();
        })}
        onCancel={destroyAllModal}
      />
    );
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          name: '操作',
          width: 80,
          key: 'operation',
          frozen: true,
          formatter: ({ row }) => (
            <Space>
              <a
                onClick={() => this.warehouseInternalStore.handleWarehouseBinEdit(row)}
                type="link"
              >
                编辑
              </a>
              <a
                onClick={() => this.warehouseInternalStore.handleWarehouseBinDelete([row])}
                type="link"
              >
                删除
              </a>
            </Space>
          ),
        },
        {
          name: '是否启用',
          key: 'is_enabled',
          width: 60,
        },
        {
          name: '库区',
          width: 200,
          key: 'warehouse_area_name',
        },
        {
          name: '库区类型',
          width: 100,
          key: 'warehouse_area_type',
        },
        {
          name: '货架',
          width: 200,
          key: 'warehouse_shelf_name',
        },
        {
          name: '库位',
          width: 200,
          key: 'warehouse_bin_no',
        },
        {
          name: '临时库位',
          width: 80,
          key: 'is_tmp',
        },
        {
          name: '库位容量',
          width: 60,
          key: 'capacity',
        },
        {
          name: '库位类型',
          width: 80,
          key: 'typeName',
        },
        {
          name: '安全库存',
          width: 80,
          key: 'safeStockNum',
        },
      ]
        .map((info) => ({
          resizable: true,
          sortable: false,
          ...info,
        })),
      rows: [],
      primaryKeyField: 'warehouse_bin_id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/warehouseBin/index',
    },
    buttons: [
      {
        text: '导入',
        handleClick: () => {
          renderModal(
            <ImportFile
              callback={((params) => {
                const fd = new FormData();
                for (const key in params) {
                  if (Object.prototype.hasOwnProperty.call(params, key)) {
                    fd.append(key, params[key]);
                  }
                }

                return request({
                  url: '/api/cloud/baseinfo/rest/warehouse/bin/warehouseBinImport',
                  method: 'post',
                  data: fd,
                })
                  .then(() => {
                    message.success('上传成功');
                    destroyAllModal();
                  });
              })}
              onCancel={destroyAllModal}
              templateUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/downloadTemplate/库位导入模板.xlsx')}
            />
          );
        },
      },
      {
        text: '新建库位',
        handleClick: () => this.warehouseInternalStore.handleWarehouseBinCreate(),
      },
      {
        text: '批量删除',
        handleClick: () => this.warehouseInternalStore.handleWarehouseBinDelete(this.mainSubStructureModel.gridModel.selectRows),
      },
      {
        text: '设置安全库存',
        handleClick: () => this.handleEditSafeStock(),
      },
    ],
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PureData<WarehouseBinTreeItem>>({
          url: '/api/cloud/baseinfo/rest/warehouse/bin/querybyctgr',
          method: 'post',
          data: new URLSearchParams({
            ...rest,
            ...this.normalProgramme.filterItems.params,
            category_no: '000',
            search_key: 'warehouse_bin_no',
          }),
        })
          .then((info) => ({
            status: 'Successful',
            data: info,
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'search_value',
        label: '库位',
      },
    ],
    count: 4,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });

  public warehouseShelfSettingModalStore: WarehouseShelfSettingModalStore = new WarehouseShelfSettingModalStore();

  public treeStore: TreeStore = new TreeStore(this);

  public warehouseInternalStore: WarehouseInternalStore = new WarehouseInternalStore(this);
}

