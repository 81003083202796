import React from 'react';
import type { Store } from './store';
import type { WarehouseBinItem, WarehouseBinTreeItem } from './types';
import { WAREHOUSE_BIN_TREE_NODE_TYPE } from './types';
import { WarehouseBinAddAndEditModal } from './warehouseBinAddAndEditModal';
import { action } from 'mobx';
import { message, Modal } from 'antd';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import { WarehouseAreaAddAndEditModal } from './warehouseAreaAddAndEditModal';
import { WarehouseShelfAddAndEditModal } from './warehouseShelfAddAndEditModal';

export class WarehouseInternalStore {
  constructor(private parent: Store) {
  }

  @action public handleWarehouseBinDelete = (warehouseBindRows: WarehouseBinItem[]) => {
    if (!warehouseBindRows || warehouseBindRows.length === 0) {
      const error = '请选择库位';
      message.warn({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      content: '确认删除?',
      onOk: () => request({
        url: '/api/cloud/baseinfo/rest/warehouse/bin/delete',
        method: 'post',
        data: new URLSearchParams({
          ids: warehouseBindRows.map((item) => item.warehouse_bin_id)
            .join(','),
        }),
      })
        .then(() => {
          this.parent.mainSubStructureModel.gridModel.clearToOriginal();
          this.parent.mainSubStructureModel.onRefresh();
        }),
    });
  };

  @action public handleWarehouseBinCreate = () => {
    if (this.parent.treeStore.selectedItem && this.parent.treeStore.selectedItem.type === WAREHOUSE_BIN_TREE_NODE_TYPE.warehouseShelf) {
      renderModal(
        <WarehouseBinAddAndEditModal
          callback={(async(data) => {
            await request({
              url: '/api/cloud/baseinfo/rest/warehouse/bin/insert',
              method: 'post',
              data: {
                ...data,
                warehouse_shelf_id: this.parent.treeStore.selectedItem?.id,
              },
            });

            message.success('新建成功');
            this.parent.mainSubStructureModel.onQuery();
            destroyAllModal();
          })}
          onCancel={destroyAllModal}
          title="新建库位"
        />
      );
    } else {
      const error = '请选择货架';
      message.warning({
        key: error,
        content: error,
      });
    }
  };

  @action public handleWarehouseBinEdit = (warehouseBinItem: WarehouseBinItem) => {
    renderModal(
      <WarehouseBinAddAndEditModal
        callback={(async(data) => {
          await request({
            url: `/api/cloud/baseinfo/rest/warehouse/bin/${warehouseBinItem.warehouse_bin_id}`,
            method: 'put',
            data: {
              ...data,
              warehouse_shelf_id: this.parent.treeStore.selectedItem?.id,
              warehouse_bin_id: warehouseBinItem.warehouse_bin_id,
              is_tmp: Boolean(data.is_tmp),
              is_enabled: 1,
            },
          });

          message.success('编辑成功');
          this.parent.mainSubStructureModel.onQuery();
          destroyAllModal();
        })}
        initialValues={{
          capacity: warehouseBinItem.capacity,
          warehouse_bin_no: warehouseBinItem.warehouse_bin_no,

          // 暂时用中文判断
          is_tmp: warehouseBinItem.is_tmp === '是',
        }}
        onCancel={destroyAllModal}
        title="编辑库位"
      />
    );
  };

  @action public handleWarehouseAreaCreate = (treeNodeForWarehouse: WarehouseBinTreeItem) => {
    renderModal(
      <WarehouseAreaAddAndEditModal
        callback={(async(data) => {
          await request({
            url: '/api/cloud/baseinfo/rest/warehouse/area/insert',
            method: 'post',
            data: {
              ...data,
              warehouse_id: treeNodeForWarehouse.id,
              warehouse_area_type: data.warehouse_area_type || -1,
            },
          });

          message.success('操作成功');
          this.parent.treeStore.getTreeData();
          destroyAllModal();
        })}
        onCancel={destroyAllModal}
        title="新建库区"
      />
    );
  };

  @action public handleWarehouseAreaEdit = (treeNodeForWarehouseArea: WarehouseBinTreeItem) => {
    renderModal(
      <WarehouseAreaAddAndEditModal
        callback={(async(data) => {
          await request({
            url: `/api/cloud/baseinfo/rest/warehouse/area/${treeNodeForWarehouseArea.id}`,
            method: 'put',
            data: {
              ...data,
              warehouse_area_id: treeNodeForWarehouseArea.id,
              warehouse_id: treeNodeForWarehouseArea.pid,
              warehouse_area_type: data.warehouse_area_type || -1,
            },
          });

          message.success('操作成功');
          this.parent.treeStore.getTreeData();
          destroyAllModal();
        })}
        onCancel={destroyAllModal}
        title="编辑库区"
        treeNodeForWarehouseArea={treeNodeForWarehouseArea}
      />
    );
  };

  @action public handleWarehouseAreaDelete = (treeNodeForWarehouseArea: WarehouseBinTreeItem) => {
    Modal.confirm({
      content: '确认删除?',
      onOk: () => request({
        url: '/api/cloud/baseinfo/rest/warehouse/area/delete',
        method: 'post',
        data: new URLSearchParams({ ids: treeNodeForWarehouseArea.id }),
      })
        .then(() => {
          message.success('删除成功');
          this.parent.treeStore.getTreeData();
        }),
    });
  };

  @action public handleWarehouseShelfCreate = (treeNodeForWarehouseArea: WarehouseBinTreeItem) => {
    renderModal(
      <WarehouseShelfAddAndEditModal
        callback={(async(data) => {
          await request({
            url: '/api/cloud/wms/rest/warehouse/shelf/insert',
            method: 'post',
            data: {
              ...data,
              warehouse_area_id: treeNodeForWarehouseArea.id,
            },
          });

          message.success('操作成功');
          this.parent.treeStore.getTreeData();
          destroyAllModal();
        })}
        onCancel={destroyAllModal}
        title="新建货架"
      />
    );
  };

  @action public handleWarehouseShelfEdit = (treeNodeForWarehouseShelf: WarehouseBinTreeItem) => {
    renderModal(
      <WarehouseShelfAddAndEditModal
        callback={(async(data) => {
          await request({
            url: `/api/cloud/wms/rest/warehouse/shelf/${treeNodeForWarehouseShelf.id}`,
            method: 'put',
            data: {
              ...data,
              warehouse_shelf_id: treeNodeForWarehouseShelf.id,
              warehouse_area_id: treeNodeForWarehouseShelf.pid,
            },
          });

          message.success('操作成功');
          this.parent.treeStore.getTreeData();
          destroyAllModal();
        })}
        onCancel={destroyAllModal}
        title="编辑货架"
        treeNodeForWarehouseShelf={treeNodeForWarehouseShelf}
      />
    );
  };

  @action public handleWarehouseShelfDelete = (treeNodeForWarehouseShelf: WarehouseBinTreeItem) => {
    Modal.confirm({
      content: '确认删除?',
      onOk: () => request({
        url: '/api/cloud/wms/rest/warehouse/shelf/delete',
        method: 'post',
        data: new URLSearchParams({ ids: treeNodeForWarehouseShelf.id }),
      })
        .then(() => {
          message.success('删除成功');
          this.parent.treeStore.getTreeData();
        }),
    });
  };
}

