export const WAREHOUSE_SHELF_BIND_TYPE = {
  owner: {
    value: '1',
    label: '按货主',
  },
  supplier: {
    value: '2',
    label: '按供应商',
  },
  sku: {
    value: '3',
    label: '按SKU',
  },
};

export const WAREHOUSE_SHELF_BIND_STATUS = {
  yes: {
    value: '1',
    label: '已设置',
  },
  no: {
    value: '0',
    label: '未设置',
  },
};
